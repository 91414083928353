<template>
  <div>
    <DataTable
      v-model:filters="filters"
      :value="items"
      :loading="loading"
      :rows="10"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      class="p-datatable-rekonsiliasi"
      dataKey="id"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      row-hover
      paginator
      scrollable
      filterDisplay="row"
      lazy
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column
        dataType="date"
        filter-field="pajak_between"
        field="pi_at"
        header="Tanggal"
        style="min-width: 20rem"
        headerStyle="min-width: 20rem"
        sortable
      >
        <template #body="{ data }">
          {{ formatDate(data.pi_at) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <CalendarFilter
            ref="calendarFilter"
            v-model="filterModel.value"
            @select="filterCallback()"
          />
        </template>
      </Column>
      <Column
        filter-field="pi_no"
        field="pi_no"
        header="No Bukti"
        style="min-width: 15rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        filter-field="inv_no"
        field="inv_no"
        header="Faktur"
        style="min-width: 12rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        filter-field="supplier"
        field="supplier.nama"
        header="Nama Supplier"
        style="min-width: 20rem"
        sortable
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            @keydown.enter="filterCallback()"
          />
        </template>
      </Column>
      <Column
        filter-field="masa_pajak"
        sort-field="masa_pajak"
        field="masa_pajak_desc"
        header="Masa Pajak"
        style="min-width: 15rem"
        sortable
      >
        <template #body="{ data }">
          <div>
            {{ data.masa_pajak_desc }}
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="list_masa_pajak"
            optionValue="id"
            optionLabel="nama"
            :showClear="true"
            class="p-column-filter"
            @change="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="jumlah"
        header="DPP"
        style="min-width: 8rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.dpp_pi) }}
        </template>
      </Column>
      <Column
        field="diskon"
        header="Diskon"
        style="min-width: 9rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.diskon) }}
        </template>
      </Column>
      <Column
        field="ppn"
        header="PPN"
        style="min-width: 9rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.ppn_pi) }}
        </template>
      </Column>
      <Column
        field="jumlah"
        header="Total"
        style="min-width: 9rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah) }}
        </template>
      </Column>
      <Column
        field=""
        header="DPP Retur"
        style="min-width: 9rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ !data.pajak_no ? formatCurrency(0) : formatCurrency(data.dpp_pr) }}
        </template>
      </Column>
      <Column
        field=""
        header="PPN Retur"
        style="min-width: 9rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ !data.pajak_no ? formatCurrency(0) : formatCurrency(data.ppn_pr) }}
        </template>
      </Column>
      <Column
        field="pajak_no"
        header="No Faktur Pajak"
        style="min-width: 11rem"
      />
      <Column
        field="dpp_pi"
        header="DPP Faktur Pajak"
        style="min-width: 11rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.dpp_pjk) }}
        </template>
      </Column>
      <Column
        field="ppn_pi"
        header="PPN Faktur Pajak"
        style="min-width: 11rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.ppn_pjk) }}
        </template>
      </Column>
      <Column
        filter-field="status_lunas"
        field="updated_at"
        header="Tgl Lunas"
        style="min-width: 13rem"
      >
        <template #body="{ data }">
          {{ formatDate(data.date_paid) }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="list_status"
            optionValue="value"
            optionLabel="label"
            optionDisabled="disabled"
            class="w-full"
            input-class="w-full"
            @change="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="jumlah_total"
        header="JUMLAH LUNAS"
        style="min-width: 8rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          <span v-if="data.date_paid !== null">{{
            formatCurrency(data.jumlah_total, 2, 2)
          }}</span>
          <span v-else>{{ formatCurrency(0, 2, 2) }}</span>
        </template>
      </Column>
      <Column
        field="cn"
        header="CN"
        style="min-width: 8rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          <span v-if="data.date_paid !== null">{{
            formatCurrency(data.cn, 2, 2)
          }}</span>
          <span v-else>{{ formatCurrency(0, 2, 2) }}</span>
        </template>
      </Column>
      <Column
        field="dn"
        header="DN"
        style="min-width: 8rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          <span v-if="data.date_paid !== null">{{
            formatCurrency(data.dn, 2, 2)
          }}</span>
          <span v-else>{{ formatCurrency(0, 2, 2) }}</span>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { formatCurrency, formatDate } from '@/helpers'
import { FilterMatchMode } from 'primevue/api'
import CalendarFilter from '@/components/CalendarFilter'

export default {
  components: {
    CalendarFilter,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      options: this.gridOptions,
      list_status: [],
      filters: {
        pajak_between: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pi_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        inv_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
        supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
        masa_pajak: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status_lunas: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      list_masa_pajak: [
        { id: 0, nama: '' },
        { id: 1, nama: 'Januari' },
        { id: 2, nama: 'Februari' },
        { id: 3, nama: 'Maret' },
        { id: 4, nama: 'April' },
        { id: 5, nama: 'Mei' },
        { id: 6, nama: 'Juni' },
        { id: 7, nama: 'Juli' },
        { id: 8, nama: 'Agustus' },
        { id: 9, nama: 'September' },
        { id: 10, nama: 'Oktober' },
        { id: 11, nama: 'November' },
        { id: 12, nama: 'Desember' },
      ],
    }
  },
  watch: {
    'options.filters'(value) {
      if (!value.pajak_between.value) {
        this.$refs.calendarFilter.clearValueOnly()
      }
    },
  },
  mounted() {
    this.list_status = [
      { value: '0', label: 'Belum Lunas' },
      { value: '1', label: 'Sudah Lunas' },
    ]
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.filters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.page = 1
      this.options.filters = this.filters
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>
